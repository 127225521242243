import Vue from 'vue'

import config from '@/config'

import App from './App.vue'
import router from './router'

import vuetify from '@/plugins/vuetify'
import firebasePlugin from '@/plugins/firebase'
import dictionaryPlugin from '@/plugins/dictionary'
import videoRecordPlugin from '@/plugins/videorecord'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import { firestorePlugin } from 'vuefire'

Vue.use(firestorePlugin)
Vue.use(firebasePlugin, {
  firebase: config.firebase,
})
Vue.use(dictionaryPlugin)
Vue.use(videoRecordPlugin)

// Make BootstrapVue available throughout your project
// Optionally install the BootstrapVue icon components plugin
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
