<template>
  <div>
    <div v-if="videoExist === false">
      <h6 class="pb-2 text-h6 text-center">{{ $t('h6msg2') }}</h6>
      <v-row no-gutters justify="center" class="pt-2 pb-2">
        <template v-if="isPreviewDisabled">
          <v-col class="col-6 col-md-3 pt-1 text-center">
            <v-btn color="error" @click="startRecording" outlined>
              <v-icon left>mdi-camera</v-icon>
              {{ $t('record') }}
            </v-btn>
          </v-col>
        </template>
        <template v-else>
          <v-col class="col-6 col-md-3 pt-1 text-center">
            <v-btn
              color="secondary"
              @click="previewVideo"
              :disabled="isPreviewDisabled"
              outlined
            >
              <v-icon left>mdi-play</v-icon>
              {{ $t('preview') }}
            </v-btn>
          </v-col>
          <v-col class="col-6 col-md-3 pt-1 text-center">
            <v-btn
              color="error"
              @click="retakeVideo"
              :disabled="isRetakeDisabled"
              outlined
            >
              <v-icon left>mdi-update</v-icon>
              {{ $t('retake') }}
            </v-btn>
          </v-col>
          <v-col class="col-6 col-md-3 pt-1 text-center">
            <v-btn
              color="secondary"
              @click="changeCamera"
              :disabled="!isMoreThenOneCamera"
              outlined
            >
              <v-icon left>mdi-camera-flip</v-icon>
              {{ $t('change') }}
            </v-btn>
          </v-col>
        </template>
        <v-col class="col-6 col-md-3 pt-1 text-center">
          <v-btn
            color="primary"
            @click="submitVideo"
            :disabled="isSaveDisabled"
            outlined
          >
            <template v-if="!submitting">
              <v-icon left>mdi-check-decagram</v-icon>
              {{ $t('submit') }}
            </template>
            <template v-else>
              <v-progress-circular
                indeterminate
                size="24"
              ></v-progress-circular>
              {{ currentSubmitStatus }}
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div>
      <Player
        v-if="sources"
        @downloadFile="downloadVideoFile"
        :sources="sources"
      />
      <div v-if="videoExist === false" id="videoContainer"></div>
    </div>
    <template v-if="videoExist === false">
      <div class="d-flex justify-content-center mt-5">
        <v-file-input
          :label="$t('gallery')"
          filled
          prepend-icon="mdi-camera"
          v-model="fileObj"
          @change="importFile"
          accept="video/*,.mkv"
          :state="Boolean(fileObj)"
          :file-name-formatter="formatNames"
        ></v-file-input>
      </div>
    </template>
  </div>
</template>

<script>
import 'webrtc-adapter'
import 'recordrtc'
import 'videojs-record/dist/videojs.record.js'

import videojs from 'video.js'

import 'video.js/dist/video-js.css'
import 'videojs-record/dist/css/videojs.record.css'

import { saveAs } from 'file-saver'
import Player from '@/components/Player'

import { getDownloadURL } from 'firebase/storage'

export default {
  props: {
    code: String,
    loading: Boolean,
  },
  components: {
    Player,
  },
  data() {
    return {
      fileObj: null,
      codeObject: null,

      player: null,

      retake: 0,
      deviceIndex: 0,

      isSaveDisabled: true,
      isRetakeDisabled: true,
      isPreviewDisabled: true,
      isRecording: true,

      options: {},

      videoExist: null,
      isPlayDisabled: null,

      submitting: false,
      submitted: false,

      platformDeviceIds: [],

      activeDevice: null,

      downloadUrl: null,

      docRef: null,
      sources: null,
    }
  },
  beforeCreate() {
    this.$emit('update:loading', true)
  },
  mounted() {
    this.getCode(this.code).then(() => this.$emit('update:loading', false))
  },
  computed: {
    currentSubmitStatus() {
      if (!this.submitting) return ''

      if (!this.codeObject.uploaded) return this.$t('uploading')
      if (!this.codeObject.encoded) return this.$t('encoding')
      if (!this.codeObject.encoded_mp4) return this.$t('encoding')

      return ''
    },

    isMoreThenOneCamera() {
      return this.platformDeviceIds.length > 1 && this.isRecording
    },
  },
  methods: {
    async changeCamera() {
      if (this.player.record().isRecording()) {
        this.player.record().reset()
      }

      if (this.platformDeviceIds.length <= 1) {
        throw new Error('client much have more than 1 device')
      }

      this.deviceIndex = this.deviceIndex > 0 ? 0 : 1
      const nextDevice = this.platformDeviceIds[this.deviceIndex]

      this.player.record().setVideoInput(nextDevice)

      this.activeDevice = nextDevice

      this.isSaveDisabled = true
      this.isRetakeDisabled = false

      // Continue recording with new video iteration
      // await this.startRecording()
    },

    async getCode(code) {
      try {
        const codeRef = this.$firebase.database.collection('codes').doc(code)

        codeRef.onSnapshot(async (doc) => {
          this.$emit('update:loading', true)

          try {
            const documentData = doc.data()

            console.log(documentData)

            if (this.codeObject) {
              this.onUpdate(this.codeObject, documentData)
              return
            }

            this.codeObject = documentData

            this.videoExist = !!this.codeObject.encoded

            if (this.videoExist) {
              await this.playFromStorage()
              return
            }

            await this.init(!this.videoExist)

            this.codeObject = documentData
          } finally {
            this.$emit('update:loading', false)
          }
        })
      } catch (error) {
        console.log('Error getting document:', error)
      }
    },
    onUpdate(oldDoc, newDoc) {
      if (newDoc.encoded && newDoc.encoded_mp4) {
        location.reload()
      }
      this.codeObject = { ...newDoc }
    },
    async init(canrecord) {
      this.$emit('update:loading', true)

      try {
        await this.player?.dispose()
        console.log(this.player)

        const videoContainer = document.getElementById('videoContainer')

        const video = document.createElement('video')
        video.setAttribute('id', 'myVideo')
        video.setAttribute('playsinline', '')
        video.setAttribute('autoplay', '')
        video.setAttribute('class', 'w-100 video-js vjs-default-skin')

        videoContainer.appendChild(video)

        this.options = this.$videorecord.getOptions(canrecord)
        this.player = videojs(video, this.options)

        this.player.on('deviceReady', async (e) => {
          console.log(this.isRecording, 'ON DEVICE READY')
          await this.player.record().enumerateDevices()
          console.log(this.isRecording, 'enumerateDevices')
          this.player.record().start()
          this.isRecording = true
        })

        this.player.on('enumerateReady', () => {
          const devices = this.player.record().devices

          if (this.platformDeviceIds.length === 0) {
            this.platformDeviceIds = devices
              .filter((d) => d.kind === 'videoinput')
              .map((d) => d.deviceId)
            this.activeDevice = this.platformDeviceIds[0]
          }
        })

        this.player.on('enumerateError', function (e) {
          console.log(e)
        })

        this.player.on('finishRecord', () => {
          // this.isRetakeDisabled = this.retake !== 0
          this.isSaveDisabled = false

          this.retake += 1
        })

        this.player.on('startRecord', () => {
          this.isPreviewDisabled = false
        })
      } finally {
        this.$emit('update:loading', false)
      }
    },
    async startRecording() {
      if (!this.player?.activePlugins_?.record) {
        await this.init(true)
      }
      this.fileObj = null
      try {
        this.player.record().getDevice(this.activeDevice)
        this.isRetakeDisabled = false
        this.isRecording = true
      } catch (e) {
        console.log(e)
      }
    },
    async submitVideo() {
      if (this.isRecording) {
        const data = this.player.recordedData

        this.fileObj = new File([data], this.code)
        this.player.record().stopDevice(this.activeDevice)
      }

      if (!this.fileObj) {
        return
      }

      this.isSaveDisabled = true
      this.isRetakeDisabled = true

      try {
        this.submitting = true
        await this.$firebase.storage
          .ref()
          .child(`videos/${this.code}.mkv`)
          .put(this.fileObj, { contentType: 'video/*' })

        await this.$firebase.database
          .collection('codes')
          .doc(this.code)
          .update({
            uploaded: true,
          })
      } catch (e) {
        this.submitting = false
      }
    },
    previewVideo() {
      this.player.record().pause()
      this.player.record().stop()

      this.player.currentTime(0)

      this.isRetakeDisabled = false

      this.player.play()
    },
    retakeVideo() {
      this.isSaveDisabled = true
      // this.isRetakeDisabled = true

      this.retake += 1

      this.fileObj = null

      this.player.record().getDevice(this.activeDevice)
    },
    async importFile(e) {
      this.isRecording = false
      this.isPreviewDisabled = true
      this.isSaveDisabled = false

      if (!this.fileObj) {
        this.isSaveDisabled = true

        return
      }

      this.isSaveDisabled = false
      if (this.fileObj.type !== 'video/x-matroska') {
        await this.init(false)

        this.player.src({
          src: URL.createObjectURL(this.fileObj),
          type: this.fileObj.type,
        })
      }
    },

    async playFromStorage() {
      const documentRef = this.$firebase.storage
        .ref()
        .child(`videos/encoded/${this.code}.mp4`)
      const webMDocumentRef = this.$firebase.storage
        .ref()
        .child(`videos/encoded/${this.code}.webm`)

      const mp4FileCreationDate =
        (await documentRef.getMetadata()?.timeCreated) || new Date()
      const webmFileCreationDate =
        (await webMDocumentRef.getMetadata()?.timeCreated) || new Date()

      const downloadUrl = await getDownloadURL(documentRef)
      const downloadUrlWebM = await getDownloadURL(webMDocumentRef)

      this.docRef = documentRef

      this.sources = [
        {
          src: downloadUrlWebM,
          type: 'video/webm',
          creationDate: mp4FileCreationDate,
        },
        {
          src: downloadUrl,
          type: 'video/mp4',
          creationDate: webmFileCreationDate,
        },
      ]

      this.player?.src(this.sources)
      this.player?.dispose()
    },
    async downloadVideoFile() {
      const url = await getDownloadURL(this.docRef)
      const filename = `video${Date.now().toString()}.mp4`
      saveAs(url, filename)
    },
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} files selected`
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
}
</script>

<style>
@media only screen and (max-width: 600px) {
  .video-js .vjs-big-play-button {
    left: 35% !important;
    top: 35% !important;
  }
}
@media only screen and (min-width: 601px) {
  .video-js .vjs-big-play-button {
    left: 45% !important;
    top: 45% !important;
  }
}

.custom-file > input {
  display: none;
}
</style>
