export default {
  install(Vue, options = {}) {
    Vue.prototype.$videorecord = {
      getOptions(canrecord = false) {
        const config = {
          controls: true,
          bigPlayButton: true,
          autoplay: true,
          preload: 'auto',
          loop: false,
          controlBar: {
            deviceButton: false,
            recordToggle: false,
            pipToggle: false,
          },
          width: 500,
          height: 300,
          fluid: true,
          plugins: canrecord
            ? {
                record: {
                  pip: false,
                  audio: true,
                  video: true,
                  maxLength: 60,
                  debug: true,
                },
              }
            : {},
        }
        return config
      },
    }
  },
}
