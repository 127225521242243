import deepmerge from "deepmerge";
import defaultConfig from "./default"
import productionConfig from "./production"

let config = defaultConfig

switch (process.env.NODE_ENV) {
    case 'DEVELOPMENT':
    case 'STAGE':
        // DO NOTHING

        break;

    case 'PRODUCTION':
        config = deepmerge(config, productionConfig)
        break
}

export default config