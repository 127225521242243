<template>
  <v-container>
    <div class="mt-5 mb-2"></div>
    <template v-if="loading">
      <div class="circular-wrapper">
        <v-progress-circular indeterminate size="24"></v-progress-circular>
      </div>
    </template>
    <div v-show="!loading">
      <VideoRec
        v-if="$route.params.code"
        :code="$route.params.code"
        :loading.sync="loading"
      />
      <div v-else>
        No code provided

     
      </div>

    </div>
             <div>
        <v-container>
          <br />
          <v-row justify="center">
            <a href="https://qrvideosticker.com"
              ><v-img
                max-width="201"
                max-height="75"
                src="https://cdn.shopify.com/s/files/1/0693/3794/0284/files/Minimalist_Black_and_White_Clothing_Store_Logo-01_35756a7f-e547-4881-9ba6-afca6602175d_200x.png?v=1671374252"
              ></v-img> </a></v-row
        ></v-container></div>
  </v-container>
</template>

<script>
import VideoRec from '@/components/VideoRec'

export default {
  name: 'HomeView',
  components: {
    VideoRec,
  },
  data() {
    return {
      loading: false,
    }
  },
}
</script>
<style lang="css">
.circular-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
