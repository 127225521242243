import appPackage from '../../package.json'

export default {
  version: appPackage.version,
  baseURL: process.env.BASE_URL ?? 'localhost:8080',
  firebase: {
    apiKey: "AIzaSyAeJlmhNA87-ahbHDR1CJSbFOuvO7hzs0Q",
    authDomain: "postcards-192db.firebaseapp.com",
    projectId: "postcards-192db",
    storageBucket: "postcards-192db.appspot.com",
    messagingSenderId: "1097089911306",
    appId: "1:1097089911306:web:70b0f432cc6c566b736df3",
    measurementId: "G-WZ14LCB6BQ"
    
  },
}
