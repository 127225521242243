<template>
  <div>
    <video controls style="width: 100%">
      <source
        v-for="(source, idx) in sources"
        :key="idx"
        :src="source.src"
        :type="source.type"
      />
    </video>
    <v-btn
      class="mb-3"
      @click="shareButton"
      block
      color="primary"
      elevation="2"
    >
      {{$t('share')}}
    </v-btn>
    <v-banner color="blue darken-1" elevation="1">
      {{ $t('deletedNotification', { date: getDateOfVideoExpire }) }}
      <template v-slot:actions>
        <v-btn @click.native="download()" color="white" outlined>
          <v-icon left>mdi-download-circle</v-icon>
          {{ $t('download') }}
        </v-btn>
      </template>
    </v-banner>
    <v-snackbar v-model="snackbarCopied">
      {{$t("clipboard")}}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbarCopied = false">
          {{$t('close')}}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    sources: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      snackbarCopied: false,
    }
  },
  computed: {
    getDateOfVideoExpire() {
      const existingCreationDate = this.sources.filter(
        (el) => !!el.creationDate
      )

      if (existingCreationDate.length < 1) {
        return this.addYears('', 2)
      }

      return this.addYears(existingCreationDate[0].creationDate, 2)
    },
  },
  methods: {
    async shareButton() {
      const shareData = {
        title: 'Share this video',
        url: window.location?.href,
      }

      try {
        await navigator.share(shareData)
      } catch (err) {
        await this.copyToClipboard(window.location?.href).then(() => {
          this.snackbarCopied = true
        })
      }
    },
    copyToClipboard(textToCopy) {
      // Check if navigator clipboard API is available and if the context is secure
      if (navigator.clipboard && window.isSecureContext) {
        return navigator.clipboard.writeText(textToCopy)
      } else {
        // Create a textarea element
        let textArea = document.createElement('textarea')
        textArea.value = textToCopy
        // Make the textarea out of viewport
        textArea.style.position = 'fixed'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        return new Promise((res, rej) => {
          // Use execCommand to copy the text
          document.execCommand('copy') ? res() : rej()
          textArea.remove()
        })
      }
    },
    download() {
      this.$emit('downloadFile')
    },
    addYears(date, years) {
      const numberYears = parseInt(years)
      const result = date ? new Date(date) : new Date()
      result.setFullYear(result.getFullYear() + numberYears)
      return new Date(result).toDateString()
    },
  },
}
</script>
