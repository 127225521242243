import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

import 'firebase/compat/storage'
import 'firebase/compat/functions'
// Get a Firestore instance

export default {
  install(Vue, options = { firebase: {} }) {
    const firebaseApp = firebase.initializeApp(options.firebase)

    Vue.prototype.$firebase = {
      database: firebaseApp.firestore(),
      storage: firebaseApp.storage(),
    }
  },
}
