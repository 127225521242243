import en from './en.json'
import de from './de.json'
import rs from './rs.json'
import mk from './mk.json'
import bg from './bg.json'
import al from './al.json'

const AVAILABLE_LOCALES = {
  EN: {
    code: 'en',
    schema: en,
  },
  DE: {
    code: 'de',
    schema: de,
  },
  MK: {
    code: 'mk',
    schema: mk,
  },
  AL: {
    code: 'al',
    schema: al,
  },
  RS: {
    code: 'rs',
    schema: rs,
  },
  BG: {
    code: 'bg',
    schema: bg,
  },
}

export default {
  install(Vue) {
    let currentLocale = AVAILABLE_LOCALES.EN.code
    const languagesMap = new Map([
      [AVAILABLE_LOCALES.EN.code, AVAILABLE_LOCALES.EN.schema],
      [AVAILABLE_LOCALES.DE.code, AVAILABLE_LOCALES.DE.schema],
      [AVAILABLE_LOCALES.MK.code, AVAILABLE_LOCALES.MK.schema],
      [AVAILABLE_LOCALES.AL.code, AVAILABLE_LOCALES.AL.schema],
      [AVAILABLE_LOCALES.RS.code, AVAILABLE_LOCALES.RS.schema],
      [AVAILABLE_LOCALES.BG.code, AVAILABLE_LOCALES.BG.schema],
    ])

    Vue.prototype.$setLocale = (locale = 'en') => {
      const localeExists = Object.values(AVAILABLE_LOCALES).some(
        (containerLocale) => containerLocale.code === locale
      )

      if (!localeExists) {
        throw new Error('incorrect locale set')
      }

      currentLocale = locale
    }

    Vue.prototype.$getLocale = () => {
      return currentLocale
    }

    Vue.prototype.$t = (word, args = {}) => {
      if (!languagesMap.has(currentLocale)) {
        return '[language stub]'
      }

      if (Object.keys(args).length > 0) {
        return languagesMap
          .get(currentLocale)
          [word].replace(/{{([^}]+)}}/gi, (match, val) => {
            return typeof args[val] != 'undefined' ? args[val] : match
          })
      }

      console.log(languagesMap)
      console.log(languagesMap.get(currentLocale))


      return languagesMap.get(currentLocale)[word]
    }
  },
}
