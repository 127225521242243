<template>
  <v-app style="background: #e3f2fd">
    <v-main>
      <HomeView />
    </v-main>
  </v-app>
</template>

<script>
import HomeView from './views/HomeView.vue'

export default {
  components: { HomeView },
  name: 'App',

  mounted() {
    if (this.$route.query.l) {
      this.$setLocale(this.$route.query.l)
    }
  },

  updated() {
    const localeChanged = this.$getLocale() !== this.$route.query.l

    if (localeChanged) {
      this.$setLocale(this.$route.query.l)
    }
  },

  data() {
    return {}
  },
}
</script>
